import './style.css'
import * as THREE from 'three'
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { BloomPass } from 'three/examples/jsm/postprocessing/BloomPass.js';
import { FilmPass } from 'three/examples/jsm/postprocessing/FilmPass.js';
import { DotScreenPass } from 'three/examples/jsm/postprocessing/DotScreenPass.js';
import { BleachBypassShader } from 'three/examples/jsm/shaders/BleachBypassShader.js';
import { ColorifyShader } from 'three/examples/jsm/shaders/ColorifyShader.js';
import { HorizontalBlurShader } from 'three/examples/jsm/shaders/HorizontalBlurShader.js';
import { VerticalBlurShader } from 'three/examples/jsm/shaders/VerticalBlurShader.js';
import { GammaCorrectionShader } from 'three/examples/jsm/shaders/GammaCorrectionShader.js';
import { Reflector } from 'three/examples/jsm/objects/Reflector.js';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

var params = {
  fogNearColor: 0x999999,
  fogHorizonColor: 0x000000,
  fogDensity: 0.095,
  fogNoiseSpeed: 100,
  fogNoiseFreq: .0012,
  fogNoiseImpact: .55,
  exposure: 1,
  bloomStrength: 1.5,
  bloomThreshold: 0,
  bloomRadius: 0
};
//Reflector.prototype = Object.create( THREE.Mesh.prototype );
//Reflector.prototype.constructor = Reflector;

Reflector.ReflectorShader = {
  uniforms: {
    'color': {
      type: 'c',
      value: null
    },
    'tDiffuse': {
      type: 't',
      value: null
    },
    'tDepth': {
      type: 't',
      value: null
    },
    'textureMatrix': {
      type: 'm4',
      value: null
    },
    'cameraNear': {
      type: 'f',
      value: 0
    },
    'cameraFar': {
      type: 'f',
      value: 0
    },
  },
  vertexShader: [
    'uniform mat4 textureMatrix;',
    'varying vec4 vUv;',

    'void main() {',

    '	vUv = textureMatrix * vec4( position, 1.0 );',

    '	gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );',

    '}'
  ].join( '\n' ),

  fragmentShader: [
    '#include <packing>',
    'uniform vec3 color;',
    'uniform sampler2D tDiffuse;',
    'uniform sampler2D tDepth;',
    'uniform float cameraNear;',
    'uniform float cameraFar;',
    'varying vec4 vUv;',
    'float blendOverlay( float base, float blend ) {',
    '	return( base < 0.5 ? ( 2.0 * base * blend ) : ( 1.0 - 2.0 * ( 1.0 - base ) * ( 1.0 - blend ) ) );',
    '}',
    'vec3 blendOverlay( vec3 base, vec3 blend ) {',
    '	return vec3( blendOverlay( base.r, blend.r ), blendOverlay( base.g, blend.g ), blendOverlay( base.b, blend.b ) );',
    '}',
    'float readDepth( sampler2D depthSampler, vec4 coord ) {',
    '	float fragCoordZ = texture2DProj( depthSampler, coord ).x;',
    '	float viewZ = perspectiveDepthToViewZ( fragCoordZ, cameraNear, cameraFar );',
    '	return viewZToOrthographicDepth( viewZ, cameraNear, cameraFar );',
    '}',
    'void main() {',
    '	vec4 base = texture2DProj( tDiffuse, vUv );',
    ' float depth = readDepth( tDepth, vUv );',
    '	gl_FragColor = vec4( blendOverlay( base.rgb, color ), 1.0 - ( depth * 7000.0 ) );',
    '}'
  ].join( '\n' )
};

/* //////////////////////////////////////// */

// SCENE
var mouseX = 0,
    mouseY = 0;
let scene = new THREE.Scene();
//scene.background = new THREE.Color(params.fogHorizonColor);
//scene.fog = new THREE.FogExp2(params.fogHorizonColor, params.fogDensity);
scene.fog = new THREE.FogExp2( 0x000000, 0.0003 );

// Meshes
let root, mesh, groundMirror, shant, shant_root, mech_root, group, shant_gltf, mode = 0;
// Animations
let mixer_shant;
let falling, dance1, thriller, gernade, hiphop, hokey, flair, swing, walking, armUp, armDown, lookAround, shantsit, stand, musicArmDown, musicArmUp;
let bike, bike2, bike3, bike4, bike5, bike6;
// Lights
// Textures / Globals
let hdrCubeRenderTarget;
let  hdrCubeMap;
let cameraMode = 1, camera, musicCamera, videoCamera;
let renderer, render, composer, glitchPass, halftonePass, ssaoPass;
/* //////////////////////////////////////// */
var clock = new THREE.Clock();
var windowHalfX = window.innerWidth / 2;
var windowHalfY = window.innerHeight / 2;
var fogColor = new THREE.Color(0x371808);
let btnPlay = document.querySelector('.is--play');
let btnStop = document.querySelector('.is--stop');
let btnPause = document.querySelector('.is--pause');
let btnParty = document.querySelector('.is--party');
const width = window.innerWidth || 2;
const height = window.innerHeight || 2;
// Post Processing
const shaderBleach = BleachBypassShader;
const effectBleach = new ShaderPass( shaderBleach );
const effectBloom = new BloomPass( 0.5 );
const effectFilm = new FilmPass( 0.55, 0, 900, false );
effectBleach.uniforms[ 'opacity' ].value = 0.95;
//const stats = Stats()
const delta = 0.01;
let easeindone = 0;
const mouse = new THREE.Vector2();
const effectHBlur = new ShaderPass( HorizontalBlurShader );
const effectVBlur = new ShaderPass( VerticalBlurShader );
effectHBlur.uniforms[ 'h' ].value = 2 / ( width / 2 );
effectVBlur.uniforms[ 'v' ].value = 2 / ( height / 2 );

const effectColorify1 = new ShaderPass( ColorifyShader );
const effectColorify2 = new ShaderPass( ColorifyShader );
effectColorify1.uniforms[ 'color' ] = new THREE.Uniform( new THREE.Color( 1, 0.8, 0.8 ) );
effectColorify2.uniforms[ 'color' ] = new THREE.Uniform( new THREE.Color( 1, 0.75, 0.5 ) );
const gammaCorrection = new ShaderPass( GammaCorrectionShader );
const boom = new THREE.Group();
var container, aspectRatio,
    HEIGHT, WIDTH, fieldOfView,
    nearPlane, farPlane,
    mouseX, mouseY, windowHalfX,
    windowHalfY, stats, geometry,
    starStuff, materialOptions, stars;

function init() {
  var canvas = document.getElementById("mech_box");
  // CAMERA
  camera = new THREE.PerspectiveCamera( 23, window.innerWidth / window.innerHeight, 0.001, 1000 );
  //camera.position.set(-16, 0.5, 5 );
  //camera.rotate.y = 90 * Math.PI / 180
  /* //////////////////////////////////////// */

  // RENDERER
  renderer = new THREE.WebGLRenderer( {  alpha: true, antialias: true, logarithmicDepthBuffer: true, canvas: canvas } );
  renderer.toneMapping = THREE.ReinhardToneMapping;
  renderer.shadowMap.enabled = true;

  renderer.setSize(window.innerWidth, window.innerHeight);
  window.addEventListener("resize", onWindowResize, false);
  window.addEventListener( 'mousemove', onMouseMove, false );
  const rtParameters = {
    stencilBuffer: true
  };
  const light3 = new THREE.PointLight( 0xFFFFFF, 2, 100 );
  light3.position.set( -6, 6, -10);
  scene.add( light3 );

  let directionalLight6 = new THREE.SpotLight(0xFF88FF, 10 );
  directionalLight6.castShadow = true;
  directionalLight6.position.set(12,0,10);
  boom.add(camera);
  scene.add(boom);
  camera.position.set( 0, 3.8, 3 ); // this sets the boom's length
  camera.lookAt( 0, 3.8, 0 ); // camera looks at the boom's zero
  boom.rotation.y = 2;
  const hdrUrl = 'https://serv.meshdesignco.com/Shant/dist/HdrStudioReflectionsStarfield001_JPG_2K.jpg';

  //dark-hanger
  hdrCubeMap = new THREE.TextureLoader().load(hdrUrl, texture => {
    const gen = new THREE.PMREMGenerator(renderer)
    const envMap = gen.fromEquirectangular(texture).texture
    scene.environment = envMap
    texture.dispose()
    gen.dispose()
  } );
  //scene.background = hdrCubeMap;
  /* //////////////////////////////////////// */
  const gltfLoader = new GLTFLoader()
  const dracoLoader = new DRACOLoader();
  dracoLoader.setDecoderPath( 'https://serv.meshdesignco.com/Shant2/dist/draco/' );
  gltfLoader.setDRACOLoader( dracoLoader );
  const loader = new THREE.TextureLoader();
  gltfLoader.load("https://serv.meshdesignco.com/Shant/dist/shantfullblend.glb", (gltf) => {
    gltf.scene.rotation.y = Math.PI / 0.655;
    shant_gltf = gltf;
    shant = gltf.scene;
    shant.scale.set(2, 2, 2);
    shant.position.y = 1.5;
    shant.castShadow = true;
    shant.traverse(function(child) {
      if (child.isMesh) {
        child.castShadow = true;
        child.receiveShadow = true;
        child.frustumCulled = false;
      }
    })
    for (let child of shant.children) {
      loader.load(
          hdrUrl,
          function ( texture ) {
            texture.flipY = false;
            if (child.material){
              let newMat = child.material.clone();
              child.material = newMat;
              child.material.envMapIntensity = 1;
              child.material.needsUpdate = true;
              child.geometry.computeVertexNormals();
            }
          });
    }
    mixer_shant = new THREE.AnimationMixer(shant);

    bike = shant.getObjectByName("vespa004");
    bike2 = shant.getObjectByName("asymmetric");
    bike3 = shant.getObjectByName("Cylinder005");
    bike4 = shant.getObjectByName("Cylinder002");
    bike5 = shant.getObjectByName("Circle");
    bike6 = shant.getObjectByName("Plane002");
    dance1 = THREE.AnimationClip.findByName(gltf,"hokey");
    falling = THREE.AnimationClip.findByName(gltf,"FALLING");
    gernade = THREE.AnimationClip.findByName(gltf,"GERNADE");
    hiphop = THREE.AnimationClip.findByName(gltf,"HIPHOP");
    stand = THREE.AnimationClip.findByName(gltf,"stand");
    thriller = THREE.AnimationClip.findByName(gltf,"Thriller");
    swing = THREE.AnimationClip.findByName(gltf,"SWING");
    walking = THREE.AnimationClip.findByName(gltf,"WALKING");
    flair = THREE.AnimationClip.findByName(gltf,"flair");
    hokey = THREE.AnimationClip.findByName(gltf,"hokey");
    mixer_shant.clipAction(walking).play();
    scene.add(shant);
  });
  //createBase();
  let geometry = new THREE.CircleGeometry( 40, 64 );
  groundMirror = new Reflector( geometry, {
    clipBias: 0.05,
    textureWidth: window.innerWidth * window.devicePixelRatio,
    textureHeight: window.innerHeight * window.devicePixelRatio,
    color: 0x777777,
    recursion: 1
  } );
  groundMirror.fadedReflection = true;
  groundMirror.position.y = 1.5;
  groundMirror.opacity = 0.6;
  groundMirror.material.blur = 0.75;
  groundMirror.material.needsUpdate = true;
  groundMirror.rotateX( - Math.PI / 2 );
  scene.add( groundMirror );

  composer = new EffectComposer( renderer, new THREE.WebGLRenderTarget( width, height, rtParameters ) );
  composer.addPass( new RenderPass( scene, camera ) );
  composer.addPass( gammaCorrection );
  //composer.addPass( effectBloom );
  composer.addPass( effectFilm );
  composer.addPass( effectBleach );
  /* --- Cool Gold ---
  composer.addPass( gammaCorrection );
  composer.addPass( effectDotScreen );
  composer.addPass( effectColorify1 );
  composer.addPass( effectColorify2 );
  */
}
//
//  Scroll managment
function onMouseMove( event ) {
  // calculate mouse position in normalized device coordinates
  // (-1 to +1) for both components
  mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
  mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 2;
}

/* //////////////////////////////////////// */
function onWindowResize() {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();
  renderer.setSize( window.innerWidth, window.innerHeight );
  render();
}
let count = 0;
let count2 = 0;

function addSoundControls() {
  btnPlay.addEventListener('click', () => {
    dance1Start();
  });
  btnPause.addEventListener('click', () => {
    fallStart();
  });
  btnStop.addEventListener('click', () => {
    fallEnd();
  });
  btnParty.addEventListener('click', () => {
    thrillerStart();
  });
  /*
  btnDitchParty.addEventListener('click', () => {
    ditchParty();
  });
  \
   */
}
function animate() {
  let renderTarget, cubeMap;
  renderTarget = hdrCubeRenderTarget;
  if ( mixer_shant ) mixer_shant.update( clock.getDelta() );
  const time = clock.getElapsedTime();
  if (mode == 0) {
    if (shant) {
      if (boom.rotation.y > -1.5) {
        boom.rotation.y -= 0.07;
      } else {
        easeindone = 1;
      }
      if (camera.position.z < 13) {
        camera.position.z += 0.23;
      }
      if (easeindone == 1) {
        gsap.to(camera.position, { duration: 1, x: mouse.x * 4})
        gsap.to(camera.position, { duration: 1, y: 2 + mouse.y * 4})
        camera.lookAt( 0, 3.8, 0 );
        camera.position.z = 13
      }
    }
  } else if (mode == 1) {
    boom.rotation.y += 0.004;
    if (camera.position.y < 5) {
      boom.position.y = +0.01;
    }
  } else if (mode == 3) {
      boom.position.y = 0;
      boom.rotation.y = 0.25;
  }
  //groundMirror.material.needsUpdate = true;
  renderer.render(scene, camera);
  composer.render( delta );
  requestAnimationFrame(animate);
}


export default function changeAnimation(id) {
  console.log("Change Animation");
  console.log(id);
  switch(id) {
    case "1":
      fallStart();
      break;
    case "2":
      fallEnd();
      break;
    case "3":
      dance1Start();
      break;
    case "4":
      dance1Stop();
      break;
    case "5":
      thrillerStart();
      break;
    case "6":
      thrillerStop();
      break;
    case "7":
      hokeyStart();
      break;
    case "8":
      hokeyStop();
      break;
    case "9":
      flairStart()
      break;
    case "10":
      flairStop();
      break;
    case "13":
      scramAnimation();
      break;
    default:
      // code block
  }
}

function scramAnimation() {
  mixer_shant.clipAction(falling).stop();
  mixer_shant.clipAction(dance1).stop();
  mixer_shant.clipAction(hokey).stop();
  mixer_shant.clipAction(flair).stop();
  mixer_shant.clipAction(thriller).stop();
  mixer_shant.clipAction(walking).play();
}
function clearAnimation() {
  if (mode == 1) {
    bike.visible = true;
    bike2.visible = true;
    bike3.visible = true;
    bike4.visible = true;
    bike5.visible = true;
    bike6.visible = true;
    scene.add(groundMirror);
  }
  //camera.fov = 23;
  //camera.updateProjectionMatrix();
  mixer_shant.clipAction(falling).stop();
  mixer_shant.clipAction(dance1).stop();
  mixer_shant.clipAction(hokey).stop();
  mixer_shant.clipAction(flair).stop();
  mixer_shant.clipAction(walking).stop();
  mixer_shant.clipAction(thriller).stop();
}

function fallStart() {
  bike.visible = false;
  bike2.visible = false;
  bike3.visible = false;
  bike4.visible = false;
  bike5.visible = false;
  bike6.visible = false;
  mode = 1;
  scene.remove(groundMirror);
  mixer_shant.clipAction(walking).stop();
  mixer_shant.clipAction(falling).play();
}

function fallEnd() {
  bike.visible = true;
  bike2.visible = true;
  bike3.visible = true;
  bike4.visible = true;
  bike5.visible = true;
  bike6.visible = true;
  scene.add(groundMirror);
  mode = 0;
  mixer_shant.clipAction(falling).stop();
  mixer_shant.clipAction(walking).play();
}

function dance1Start() {
  clearAnimation();
  mixer_shant.clipAction(dance1).play();
  mode = 3;
}

function dance1Stop() {
  clearAnimation();
  mixer_shant.clipAction(walking).play();
  mode = 0;
}

function thrillerStart() {
  clearAnimation();
  scene.add(groundMirror);
  camera.fov = 42;
  camera.updateProjectionMatrix();
  mixer_shant.clipAction(thriller).play();
  mode = 0;
}

function thrillerStop() {
  clearAnimation();
  bike.visible = false;
  bike2.visible = false;
  bike3.visible = false;
  bike4.visible = false;
  bike5.visible = false;
  bike6.visible = false;
  camera.fov = 23;
  mode = 1;
  camera.updateProjectionMatrix();
  mixer_shant.clipAction(thriller).stop();
  mixer_shant.clipAction(falling).play();
  scene.remove(groundMirror);
}
function hokeyStart() {
  clearAnimation();
  mixer_shant.clipAction(hokey).play();
  mode = 0;
}
function hokeyStop() {
  clearAnimation();
  mixer_shant.clipAction(walking).play();
  mode = 0;
}
function flairStart() {
  clearAnimation();
  mixer_shant.clipAction(flair).play();
  mode = 0;
}
function flairStop() {
  clearAnimation();
  mixer_shant.clipAction(walking).play();
  mode = 0;
}

document.addEventListener("DOMContentLoaded", function() {
  init();
  //addSoundControls();
  animate();
});
/* //////////////////////////////////////// */