(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define("changeAnimation", [], factory);
	else if(typeof exports === 'object')
		exports["changeAnimation"] = factory();
	else
		root["changeAnimation"] = factory();
})(self, () => {
return 